<template>
    <v-dialog v-model="show" max-width="400">
        <template #activator="{ on }">
            <slot name="button" v-bind:on="on">
                <v-btn
                    v-on="on"
                    :block="!icon"
                    :icon="icon"
                    rounded
                    :color="color">
                    <span v-if="!icon">{{ label || $t('salepointhome.qr') }}</span>
                    <v-icon v-else>$vuetify.icons.baseQr</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <acs-base-card-title :title="$t('qrcodebtn.title')" @close="show = false" />
            <v-card-text class="pa-0">

                <v-select
                    v-model="cameraId"
                    :items="cameras"
                    class="acs-camera--selector"
                    item-text="label"
                    item-value="deviceId"
                    dense
                    hide-details
                    single-line
                    solo-inverted />

                <qrcode-stream v-if="grantPermission && iOSDbg" ref="qr" @decode="scan" @init="onInit" :camera="cameraId">
                    <v-alert type="error" v-if="error">{{ $t(`qrcodebtn.errors.${error}`) }}</v-alert>
                    <v-layout justify-center class="mt-5" v-if="loading">
                        <v-progress-circular color="primary" indeterminate />
                    </v-layout>
                </qrcode-stream>

                <v-alert v-if="grantPermission && !iOSDbg" ref="qr" type="warning" tile>
                    <div v-marked="$t('scan.ioslow')"></div>
                </v-alert>

                <v-alert v-if="!grantPermission">
                    Nous avons besoin de votre permission pour scanner le QR
                    code de l'emplacement!
                    <v-btn block class="mt-5" color="primary" @click="grantPermission = true">Scanner</v-btn>
                </v-alert>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import VueQrcodeReader from 'vue-qrcode-reader'

Vue.use(VueQrcodeReader)

export default {
    name: 'acs-base-qrcode-btn',
    props: {
        value: { type: String },
        color: { type: String, default: 'primary' },
        label: { type: String, default: null },
        icon: { type: Boolean }
    },
    components: { AcsBaseCardTitle },
    data: () => ({
        loading: true,
        launched: false,
        grantPermission: false,
        error: null,
        show: false,
        cameras: [],
        cameraId: null
    }),
    computed: {
        iOSDbg() {
            return this.$store.getters['dbg/iOSMatch']('14.3')
        },
        code: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        iOSDbg(v) {
            this.$store.commit('dbg/log', { message: `iOS version match? ${v ? 'yes' : 'no'}` })
        },
        show(v) {
            if (!v && this.$refs.qr) {
                this.$refs.qr.beforeResetCamera && this.$refs.qr.beforeResetCamera()
            }
            if (v && this.launched) {
                this.$refs.qr.init()
            }
        }
    },
    async mounted() {
        if (!navigator.permissions) {
            this.$store.commit('dbg/log', { message: 'Scan mount no permission' })
            this.grantPermission = true
            return this.listCameras()
        }
        try {
            // Code incompatible firefox. Le grantPermission permet ici d'avoir
            // un message préalable à l'acceptation de la caméra. Si on ne l'a
            // pas, ce n'est pas bloquant
            const perm = await navigator.permissions.query({ name: 'camera' })
            this.grantPermission = perm.state !== 'prompt'
        } catch (e) {
            // en cas d'erreur, on set à true, ce qui va simplement tout de
            // suite prooposer au user d'accepter l'utilisation de la caméra
            this.$store.commit('dbg/log', { message: `Scan mount error: ${e}` })
            this.grantPermission = true
        }

        return this.listCameras()
    },
    methods: {
        onInit(promise) {
            this.loading = true
            this.error = null
            return promise
                .catch(err => {
                    this.$store.commit('dbg/log', { message: `Scan onInit ${err}` })
                    // erreur spécifique firefox qu'on veut ignorer
                    if (['TypeError'].indexOf(err.name) !== -1) {
                        this.$store.commit('dbg/log', { message: `Scan onInit ${err}` })
                        return
                    }
                    const errors = ['NotAllowedError', 'NotFoundError', 'NotSupportedError', 'NotReadableError', 'OverconstrainedError', 'StreamApiNotSupportedError']
                    const name = errors.indexOf(err.name) !== -1 ? err.name : 'unknown'
                    this.error = name.toLowerCase()
                })
                .finally(() => {
                    this.launched = true
                    this.loading = false
                })
        },

        async scan(content) {
            this.$store.commit('dbg/log', { message: 'QR scanned' })

            const reg = this.$config.local
                ? /^http:\/\/(localhost|127\.0\.0\.1):\d{1,5}\/([a-zA-Z0-9-_]+)?/
                : /^https:\/\/([a-zA-Z0-9-^.]+.)?myaccessing.app\/([a-zA-Z0-9-_]+)?/

            const result = String(content).match(reg)
            if (result) {
                this.code = content
                this.$emit('scanned', { content, slug: result[2] })
            } else {
                alert('Code QR erroné')
            }
            this.show = false
        },

        async listCameras() {
            // Ne fonctionne pas avec la version pour Vue 2...
            // const list = (await navigator.mediaDevices.enumerateDevices()).filter(({ kind }) => kind === 'videoinput')
            const list = [
                { deviceId: 'auto', kind: 'videoinput', label: 'auto' },
                { deviceId: 'rear', kind: 'videoinput', label: 'rear' },
                { deviceId: 'front', kind: 'videoinput', label: 'front' }
            ]

            this.cameras = list.filter(({ kind }) => kind === 'videoinput')

            if (this.cameras.length > 0) {
                this.cameraId = this.cameras[0].deviceId
            }
        }
    }
}
</script>

<style lang="sass">
.acs-camera--selector
    border-radius: 0
</style>
